@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ec7404;
  --secondary-color: #617082;
  --ui-background: #1d232a;
  --ui-foreground: #2c343a;
  --font-dark: black;
  --font-light: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  font-family: 'Lato';
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
  background-color: var(--ui-background);
  color: var(--font-light);
}

body > div {
  min-height: 100vh;
  max-width: 768px;
  margin-inline: auto;
  display: flex;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  gap: .25rem;
}

button,
input[type='number'] {
  background-color: var(--primary-color);
  border-radius: 15px;
  width: 154px;
  height: 54px;
  font-size: 18px;
}

button:disabled {
  background-color: var(--ui-background);
  color: var(--ui-foreground);
}

input[type='number'] {
  background-color: var(--font-dark);
  color: var(--font-light);
}

input[type='email'] {
  color: var(--font-dark);
}

h1,
h2,
h3 {
  font-weight: 700;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: -0.333333px;
}

/* A elements that don't have a class get default styles */
a {
  color: var(--font-light) !important;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

.primary-font {
  color: var(--primary-color);
}

.ui-fg {
  background-color: var(--ui-foreground);
}

.ui-bg {
  background-color: var(--ui-background);
}

.ui-black {
  background-color: var(--font-dark);
}

.ui-highlight {
  background-color: var(--secondary-color);
}

.intro-container {
  background-image: url('/public/intro.png');
  background-size: 100% auto;
  background-position: top center;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: -8px;
  padding: 1rem;
  flex-grow: 1;
}

.intro-container > * {
  z-index: 2;
}

.intro-container > h3 {
  position: absolute;
  top: 50%;
  padding-inline: 1rem;
}

.intro-container::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(236, 116, 4, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  inset: 0;
  z-index: 1;
}

.reward-image {
  display: grid;
  grid-template-columns: 88px;
  grid-template-rows: 100%;
  place-items: center;
  isolation: isolate;
}

.reward-image > * {
  grid-row: 1;
  grid-column: 1;
}

.selected {
  background-color: var(--secondary-color);
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .app-container, body, body > div {
    min-height: calc(100vh - 120px);
  }
}

